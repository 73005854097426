import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import clsx from "clsx";
import { IconButton } from "./IconButton";
import { performSearch } from "../utils/search";
import { Loader } from "./Loader";
import { useKeyUp } from "../utils/useKeyUp";
import { SearchSolid, TimesSolid } from "./icons";

import styles from "./Search.scss";

const SEARCH_DEBOUNCE_DURATION = 1000;
const ESCAPE_KEY = 27;

export const Search = ({ className, onResult }) => {
    const [active, setActive] = useState(false);
    const [hide, setHide] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [result, setResult] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (hide) {
            setSearchValue(null);
            setResult(null);
        }
    }, [hide]);
    const handleAnimationEnd = useCallback(() => {
        if (hide) {
            setActive(false);
            setHide(false);
        }
    }, [hide]);
    const handleClose = useCallback(() => setHide(true), []);
    const handleInput = useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);
    const handleSearchClick = useCallback(() => {
        setActive(true);
        inputRef.current?.classList.remove(styles.show);
        inputRef.current?.focus();
        inputRef.current?.classList.add(styles.show);
    }, []);
    const handleShowResult = (result) => {
        const lat = parseFloat(result.lat);
        const lon = parseFloat(result.lng);
        onResult({ name: result.name, coordinates: [lon, lat] });
        setActive(false);
        setResult(null);
        setSearchValue(null);
    };
    useEffect(() => {
        let timeout;
        let cancelled = false;
        if (searchValue?.length > 0) {
            setResult({ loading: true });
            timeout = setTimeout(async () => {
                try {
                    const results = await performSearch(searchValue, 10);
                    if (!cancelled) {
                        setResult({ results });
                    }
                } catch (error) {
                    setResult({ error });
                }
            }, SEARCH_DEBOUNCE_DURATION);
        } else {
            setResult(null);
        }
        return () => {
            cancelled = true;
            clearTimeout(timeout);
            setResult(null);
        };
    }, [searchValue]);

    useKeyUp(ESCAPE_KEY, () => setHide(true));

    return (
        <div className={clsx(className, styles.container)}>
            <div className={clsx(styles.inputContainer, { [styles.hideInput]: !active })}>
                <input
                    ref={inputRef}
                    className={clsx(styles.input, { [styles.hide]: hide })}
                    placeholder={hide ? "" : "Sök"}
                    onAnimationEnd={handleAnimationEnd}
                    value={searchValue ?? ""}
                    onInput={handleInput}
                />
                {!hide && (
                    <IconButton
                        ariaLabel="Stäng"
                        className={styles.cancelButton}
                        variant="transparent"
                        icon={<TimesSolid />}
                        onClick={handleClose}
                    />
                )}
            </div>
            {!active && (
                <IconButton
                    className={styles.searchButton}
                    icon={<SearchSolid />}
                    ariaLabel="Sök"
                    onClick={handleSearchClick}
                />
            )}
            {result && (
                <div className={styles.resultsContainer}>
                    {result.loading && (
                        <div className={styles.loadingContainer}>
                            <Loader />
                        </div>
                    )}
                    {result.error && <p>{result.error.message}</p>}
                    {result.results?.length === 0 && <p>Inga resultat</p>}
                    {result?.results?.map((result, index) => (
                        <button key={index} className={styles.result} onClick={() => handleShowResult(result)}>
                            {result.name}
                            {result.adminName1 && `, ${result.adminName1}`}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
