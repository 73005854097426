import { memo } from "preact/compat";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { MapContext } from "./Map";

import { Overlay as OLOverlay } from "ol";

function Overlay({ children, position, positioning, autoPan }) {
    const ref = useRef(null);
    const [overlay, setOverlay] = useState(null);
    const { map } = useContext(MapContext);

    useEffect(() => {
        setOverlay(new OLOverlay({ element: ref.current, autoPan, positioning }));
    }, [autoPan, positioning]);

    useEffect(() => {
        overlay && map?.addOverlay(overlay);
        return () => {
            overlay && map?.removeOverlay(overlay);
        };
    }, [map, overlay]);

    useEffect(() => {
        overlay?.setPosition(position ?? null);
    }, [overlay, position]);

    // autoPan won't work correctly if positioning isn't set in the constructor (as of ol 6.5.0)
    // useEffect(() => {
    //     overlay?.setPositioning(positioning ?? "top-left");
    // }, [overlay, positioning]);

    return <div ref={ref}>{children}</div>;
}

export default memo(Overlay);
