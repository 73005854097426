import { useCallback, useEffect, useState } from "preact/hooks";
import { createTopowebbSource } from "../utils/topowebb-source";
import { defaults as defaultInteractions } from "ol/interaction";
import { DoubleTapZoomInteraction } from "./DoubleTapZoomInteraction";
import { fromLonLat } from "ol/proj";
import { Footer } from "./Footer";
import { GeolocationLayer } from "./GeolocationLayer";
import { IconButton } from "./IconButton";
import { Map as OLMap } from "ol-preact";
import { MapCenterer } from "./MapCenterer";
import { Measure } from "./Measure";
import { Popup } from "./Popup";
import { Tile as OLTileLayer } from "ol-preact/layer";
import { Search } from "./Search";
import { useSaveMapPosition } from "../utils/useSaveMapPosition";
import { useGeolocation } from "../utils/useGeolocation";
import { useInitialView } from "../utils/useInitialView";
import { useKeyUp } from "../utils/useKeyUp";
import { getPopupQueryParams } from "../utils/getPopupQueryParams";
import { MapMarkerAltSolid, RulerVerticalSolid } from "./icons";

import styles from "./App.scss";

const source3006 = createTopowebbSource("EPSG:3006");
const source3857 = createTopowebbSource("EPSG:3857");
const ESCAPE_KEY = 27;
const mapInteractions = defaultInteractions({
    doubleClickZoom: false,
    shiftDragZoom: false,
    pinchRotate: false,
});

export const App = () => {
    const [popupCoordinates, setPopupCoordinates] = useState(null);
    const [popupTitle, setPopupTitle] = useState(null);
    const [mapCenter, setMapCenter] = useState(null);
    const [measureEnabled, setMeasureEnabled] = useState(false);
    const { position, locationEnabled, setLocationEnabled } = useGeolocation();
    const view = useInitialView("EPSG:3006");

    useEffect(() => {
        const params = getPopupQueryParams();
        if (params) {
            const { x, y, title } = params;
            setMapCenter([x, y]);
            setPopupCoordinates([x, y]);
            title && setPopupTitle(title);
        }
    }, []);
    const handleMoveEnd = useSaveMapPosition();
    const handleMapClick = useCallback(
        (event) => {
            if (!measureEnabled) {
                setPopupCoordinates((coordinates) => (!!coordinates ? null : event.coordinate));
            }
        },
        [measureEnabled]
    );
    const handlePopupClose = useCallback(() => setPopupCoordinates(null), []);
    const handleSearchResult = useCallback((result) => {
        const coordinates = fromLonLat(result.coordinates, "EPSG:3006");
        setPopupCoordinates(coordinates);
        setPopupTitle(result.name);
        setMapCenter(coordinates);
    }, []);
    const toggleLocation = useCallback(() => setLocationEnabled((enabled) => !enabled), [setLocationEnabled]);
    const toggleMeasure = useCallback(() => setMeasureEnabled((enabled) => !enabled), []);
    useEffect(() => {
        if (!popupCoordinates && popupTitle) {
            setPopupTitle(null);
        }
    }, [popupCoordinates, popupTitle]);

    useKeyUp(ESCAPE_KEY, () => setPopupCoordinates(null));

    return (
        <div className={styles.app}>
            <div className={styles.mapContainer}>
                <OLMap
                    className={styles.map}
                    view={view}
                    interactions={mapInteractions}
                    onMoveEnd={handleMoveEnd}
                    onTrueClick={handleMapClick}
                >
                    <DoubleTapZoomInteraction disableDoubleClick={measureEnabled} />
                    <MapCenterer center={mapCenter} />
                    <OLTileLayer source={source3006} minResolution={4.5} />
                    <OLTileLayer source={source3857} maxResolution={5} />
                    <GeolocationLayer position={position} />
                    <Popup coordinates={popupCoordinates} title={popupTitle} onClose={handlePopupClose} />
                    <Measure enabled={measureEnabled} />
                </OLMap>
            </div>
            <div className={styles.buttons}>
                <Search onResult={handleSearchResult} />
                <IconButton
                    className={styles.button}
                    icon={<RulerVerticalSolid />}
                    ariaLabel="Mät"
                    active={measureEnabled}
                    onClick={toggleMeasure}
                />
                <IconButton
                    className={styles.button}
                    icon={<MapMarkerAltSolid />}
                    ariaLabel="Position"
                    active={locationEnabled}
                    onClick={toggleLocation}
                />
            </div>
            <Footer />
        </div>
    );
};
