import { useCallback } from "preact/hooks";
import { setLocalJSONStorage } from "./localJSONStorage";

export const useSaveMapPosition = () =>
    useCallback((event) => {
        const center = event.map.getView().getCenter();
        const resolution = event.map.getView().getResolution();
        setLocalJSONStorage("sverigekartan_center", center);
        setLocalJSONStorage("sverigekartan_resolution", resolution);
    }, []);
