import clsx from "clsx";
import { ExternalLinkSquareAltSolid } from "./icons";

import styles from "./Link.scss";

export const Link = ({ className, url, onClick, children, icon = <ExternalLinkSquareAltSolid /> }) => {
    if (url) {
        return (
            <a className={clsx(className, styles.link)} href={url} target="_blank" rel="noopener noreferrer">
                <span className={styles.icon}>{icon}</span>
                {children}
            </a>
        );
    } else if (onClick) {
        return (
            <button className={clsx(className, styles.link)} onClick={onClick}>
                <span className={styles.icon}>{icon}</span>
                {children}
            </button>
        );
    }
};
