import { fromLonLat } from "ol/proj";

export const getPopupQueryParams = () => {
    const params = new URL(window.location.href).searchParams;
    let x = parseFloat(params.get("x"));
    let y = parseFloat(params.get("y"));

    if (Number.isNaN(x) || Number.isNaN(y)) {
        const lon = parseFloat(params.get("lon"));
        const lat = parseFloat(params.get("lat"));
        if (!Number.isNaN(lon) && !Number.isNaN(lat)) {
            [x, y] = fromLonLat([lon, lat], "EPSG:3006");
        }
    }
    if (!Number.isNaN(x) && !Number.isNaN(y)) {
        const title = params.get("title");
        return { x, y, title };
    }
    return null;
};
