import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { LineString, Point, Polygon } from "ol/geom";
import { primaryColor } from "../sass/_variables.scss";
import { hexToRgbArray } from "./colors";

const LINE_ARROW_LENGTH_THRESHOLD_PIXELS = 60;
const LINE_ARROW_HEIGHT_PIXELS = 14;
const LINE_ARROW_WIDTH_PIXELS = 9;

export const getPositionStyle = () =>
    new Style({
        image: new CircleStyle({
            radius: 10,
            fill: new Fill({
                color: primaryColor,
            }),
            stroke: new Stroke({
                color: [255, 255, 255],
                width: 3,
            }),
        }),
    });

const primaryColorArray = hexToRgbArray(primaryColor);
export const getAccuracyStyle = () =>
    new Style({
        image: new CircleStyle({
            fill: new Fill({
                color: primaryColorArray.concat([0.2]),
            }),
        }),
    });

export const measureTextStyleFunction = (feature) => {
    const { measurement } = feature.getProperties();
    // Center length string if area is missing
    const topBaseline = !!measurement?.areaText ? "bottom" : "center";
    const topOffset = !!measurement?.areaText ? -2 : 0;
    return [
        new Style({
            text: new Text({
                fill: new Fill({
                    color: primaryColor,
                }),
                stroke: new Stroke({
                    color: "#ffffff",
                    width: 3,
                }),
                font: "bold 14px sans-serif",
                overflow: true,
                textBaseline: topBaseline,
                offsetY: topOffset,
                text: measurement?.lengthText,
            }),
        }),
        new Style({
            text: new Text({
                fill: new Fill({
                    color: "#ffffff",
                }),
                stroke: new Stroke({
                    color: primaryColor,
                    width: 3,
                }),
                font: "bold 14px sans-serif",
                overflow: true,
                textBaseline: "top",
                offsetY: 2,
                text: measurement?.areaText,
            }),
        }),
    ];
};

export const measureStyleFunction = (feature) => {
    const type = feature.getGeometry().getType();
    if (type === "LineString") {
        return [
            new Style({
                stroke: new Stroke({
                    color: primaryColor,
                    width: 3,
                }),
            }),
        ];
    } else if (type === "Polygon") {
        return [
            ...measureTextStyleFunction(feature),

            new Style({
                fill: new Fill({
                    color: [255, 255, 255, 0.7],
                }),
            }),

            // First vertex
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: "#ffffff",
                    }),
                    stroke: new Stroke({
                        color: primaryColor,
                        width: 2,
                    }),
                }),
                geometry: (feature) => {
                    const vertices = feature.getGeometry().getCoordinates()[0].slice();
                    if (vertices.length > 3) {
                        return new Point(vertices[0]);
                    }
                },
            }),

            // Last vertex
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: primaryColor,
                    }),
                    stroke: new Stroke({
                        color: "#ffffff",
                        width: 2,
                    }),
                }),
                geometry: (feature) => {
                    const vertices = feature.getGeometry().getCoordinates()[0].slice();
                    if (vertices.length === 2) {
                        // Did just begin measurement
                        return new Point(vertices[0]);
                    } else if (vertices.length > 3) {
                        return new Point(vertices[vertices.length - 3]);
                    }
                },
            }),
        ];
    }
    return null;
};

export const completedMeasureStyleFunction = (feature, resolution) => {
    const styles = [
        ...measureTextStyleFunction(feature),
        new Style({
            stroke: new Stroke({
                color: primaryColor,
                width: 3,
            }),
            fill: new Fill({
                color: [255, 255, 255, 0.7],
            }),
        }),
    ];
    if (feature.getGeometry().getType() === "LineString") {
        const stroke = new Stroke({
            color: primaryColor,
            width: 3,
        });
        const fill = new Fill({
            color: primaryColor,
        });
        feature.getGeometry().forEachSegment((start, end) => {
            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
            const lengthPixels = length / resolution;
            if (lengthPixels > LINE_ARROW_LENGTH_THRESHOLD_PIXELS) {
                const line = new LineString([start, end]);
                const [centerX, centerY] = line.getCoordinateAt(0.5);
                const top = [centerX, centerY + LINE_ARROW_HEIGHT_PIXELS * resolution];
                const left = [centerX - (LINE_ARROW_WIDTH_PIXELS / 2) * resolution, centerY];
                const right = [centerX + (LINE_ARROW_WIDTH_PIXELS / 2) * resolution, centerY];
                const polygon = new Polygon([[top, left, right, top]]);
                const rotation = Math.atan2(dy, dx) - Math.PI / 2;
                polygon.rotate(rotation, [centerX, centerY]);
                styles.push(
                    new Style({
                        geometry: polygon,
                        stroke,
                        fill,
                    })
                );
            }
        });
    }
    return styles;
};
