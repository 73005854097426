import { useEffect } from "preact/hooks";

export const useKeyUp = (keyCode, handler) => {
    return useEffect(() => {
        const listener = (event) => {
            if (event.keyCode === keyCode) {
                handler(event);
            }
        };
        document.addEventListener("keyup", listener);
        return () => {
            document.removeEventListener("keyup", listener);
        };
    }, [keyCode, handler]);
};
