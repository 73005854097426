import { MapContext } from "ol-preact/Map";
import { useContext, useEffect } from "preact/hooks";

export const MapCenterer = ({ center }) => {
    const { map } = useContext(MapContext);
    useEffect(() => {
        center && map?.getView().animate({ center });
    }, [map, center]);
    return null;
};
