export const getDefaultResolution = (projection) => {
    let width;
    let height;
    switch (projection) {
        case "EPSG:3006":
            width = 650000;
            height = 1620000;
            break;
        case "EPSG:3857":
            width = 1700000;
            height = 3690000;
            break;
        default:
            return null;
    }
    const xResolution = width / window.innerWidth;
    const yResolution = height / window.innerHeight;
    return Math.max(xResolution, yResolution);
};
