import { memo } from "preact/compat";
import { useContext, useEffect, useState } from "preact/hooks";
import { VectorSourceContext } from "../source/Vector";
import { MapContext } from "../Map";

import OLModify from "ol/interaction/Modify";

function Modify({ features, onModifyEnd, style, hitDetection }) {
    const [interaction, setInteraction] = useState(null);
    const { source } = useContext(VectorSourceContext);
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (map && (features || source)) {
            const options = { style, hitDetection };
            if (features) {
                options.features = features;
            } else {
                options.source = source;
            }
            setInteraction(new OLModify(options));
        } else {
            setInteraction(null);
        }
    }, [source, map, style, features, hitDetection]);

    useEffect(() => {
        onModifyEnd && interaction?.on("modifyend", onModifyEnd);
        return () => {
            onModifyEnd && interaction?.un("modifyend", onModifyEnd);
        };
    }, [interaction, onModifyEnd]);

    useEffect(() => {
        interaction && map?.addInteraction(interaction);
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, interaction]);

    return null;
}

export default memo(Modify);
