import { useCallback, useContext, useEffect, useRef, useState } from "preact/hooks";

import { unByKey } from "ol/Observable";
import { LineString } from "ol/geom";
import { MapContext } from "ol-preact/Map";
import { Feature as OLFeature } from "ol-preact";
import { Vector as OLVectorLayer } from "ol-preact/layer";
import { Vector as OLVectorSource } from "ol-preact/source";
import { Draw as OLDraw, Modify as OLModify } from "ol-preact/interaction";

import { completedMeasureStyleFunction, measureStyleFunction, measureTextStyleFunction } from "../utils/olStyles";
import { measure } from "../utils/measure";

export const Measure = ({ enabled }) => {
    const { map } = useContext(MapContext);
    const [drawing, setDrawing] = useState(false);
    const [features, setFeatures] = useState([]);
    const [labelFeatures, setLabelFeatures] = useState([]);
    const currentFeature = useRef(null);
    const [listenerKey, setListenerKey] = useState(null);

    useEffect(() => {
        if (!enabled) {
            setFeatures([]);
            setLabelFeatures([]);
            setListenerKey(null);
        }
    }, [enabled]);
    useEffect(() => {
        return () => {
            listenerKey && unByKey(listenerKey);
        };
    }, [listenerKey]);
    const handleGeometryChanged = useCallback(
        (event) => {
            currentFeature.current?.setProperties({
                measurement: measure(event.target, map?.getView().getProjection(), true),
            });
        },
        [map]
    );
    const handleDrawStart = useCallback(
        (event) => {
            setDrawing(true);
            const labelFeature = {
                type: "Feature",
                geometry: null,
            };
            setLabelFeatures((features) => [...features, labelFeature]);
            setListenerKey(event.feature.getGeometry().on("change", handleGeometryChanged));
            currentFeature.current = event.feature;
        },
        [handleGeometryChanged]
    );
    const handleDrawEnd = useCallback(() => {
        setDrawing(false);
    }, []);
    const handleFinishCondition = useCallback(() => {
        const geometry = currentFeature.current.getGeometry();
        const measurement = currentFeature.current.getProperties().measurement;
        // Check if user has drawn a line or polygon
        let coordinates = geometry.getCoordinates();
        const polygonRing = coordinates[0];
        const last = polygonRing[polygonRing.length - 1];
        const secondLast = polygonRing[polygonRing.length - 2];
        let type = "Polygon";
        let labelCoordinates = geometry.getInteriorPoint().getCoordinates().slice(0, 2);
        if (last[0] !== secondLast[0] || last[1] !== secondLast[1]) {
            // User drew a line, convert to LineString
            coordinates = polygonRing;
            coordinates.pop();
            type = "LineString";
            const lineString = new LineString(coordinates);
            labelCoordinates = lineString.getCoordinateAt(0.5);
            delete measurement.area;
            delete measurement.areaText;
        }
        const feature = {
            type: "Feature",
            geometry: {
                type,
                coordinates,
            },
        };
        const labelFeature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: labelCoordinates,
            },
            properties: {
                measurement,
            },
        };
        setFeatures((features) => [...features, feature]);
        setLabelFeatures((features) => [...features, labelFeature]);
        return true;
    }, [map]);
    useEffect(() => {
        const handlePointerMove = (event) => {
            const features = event.target.getFeaturesAtPixel(event.pixel);
            const hit = features.some((feature) => feature.getGeometry().getType() === "Point");
            event.target.getViewport().style.cursor = hit ? "pointer" : "";
        };
        enabled && !drawing && map?.on("pointermove", handlePointerMove);
        return () => {
            enabled && !drawing && map?.un("pointermove", handlePointerMove);
        };
    }, [map, enabled, drawing]);

    if (!enabled) {
        return null;
    }
    return (
        <>
            <OLDraw
                type="Polygon"
                onDrawStart={handleDrawStart}
                onDrawEnd={handleDrawEnd}
                finishCondition={handleFinishCondition}
                style={measureStyleFunction}
                minPoints={2}
            />
            <OLVectorLayer zIndex={100} style={completedMeasureStyleFunction}>
                <OLVectorSource>
                    {features.map((feature, index) => (
                        <OLFeature key={index} id={index} geometry={feature.geometry} />
                    ))}
                </OLVectorSource>
            </OLVectorLayer>
            <OLVectorLayer zIndex={99999}>
                <OLVectorSource>
                    {!drawing && <OLModify style={() => null} hitDetection />}
                    {labelFeatures.map((feature, index) => (
                        <OLFeature
                            key={index}
                            id={index}
                            geometry={feature.geometry}
                            style={measureTextStyleFunction}
                            properties={feature.properties}
                        />
                    ))}
                </OLVectorSource>
            </OLVectorLayer>
        </>
    );
};
