import { App } from "./components/App";

import "./style.css";

import registerProjection from "./utils/register-projection";
registerProjection();

// eslint-disable-next-line react/display-name
export default () => {
    return <App />;
};
