import { memo } from "preact/compat";
import { useContext, useEffect, useState } from "preact/hooks";
import { VectorSourceContext } from "../source/Vector";
import { MapContext } from "../Map";

import OLSnap from "ol/interaction/Snap";

function Snap({ features, pixelTolerance }) {
    const [interaction, setInteraction] = useState(null);
    const { source } = useContext(VectorSourceContext);
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (map && (features || source)) {
            const options = {
                pixelTolerance,
            };
            if (features) {
                options.features = features;
            } else {
                options.source = source;
            }
            setInteraction(new OLSnap(options));
        } else {
            setInteraction(null);
        }
    }, [features, source, map]);

    useEffect(() => {
        interaction && map?.addInteraction(interaction);
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, interaction]);

    return null;
}

export default memo(Snap);
