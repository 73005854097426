import { memo } from "preact/compat";
import { useContext, useEffect, useState } from "preact/hooks";
import { VectorLayerContext } from "../layer/Vector";
import { MapContext } from "../Map";

import OLSelect from "ol/interaction/Select";

function Select({ condition, onSelect }) {
    const [interaction, setInteraction] = useState(null);
    const { layer } = useContext(VectorLayerContext);
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (layer && map) {
            const interaction = new OLSelect({
                layers: [layer],
                multi: false,
                condition,
                style: null,
            });
            setInteraction(interaction);
        } else {
            setInteraction(null);
        }
    }, [layer, map, condition]);

    useEffect(() => {
        interaction && map?.addInteraction(interaction);
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, interaction]);

    useEffect(() => {
        onSelect && interaction?.on("select", onSelect);
        return () => {
            onSelect && interaction?.un("select", onSelect);
        };
    }, [interaction, onSelect]);

    return null;
}

export default memo(Select);
