import { memo } from "preact/compat";
import { useEffect, useContext, useRef } from "preact/hooks";
import { MapContext } from "../Map";

import { Tile as OLTileLayer } from "ol/layer";

function Tile({ source, maxResolution, minResolution }) {
    const layer = useRef(new OLTileLayer({ source }));
    const { map } = useContext(MapContext);

    useEffect(() => {
        const _layer = layer.current;
        map?.addLayer(_layer);
        return () => {
            map?.removeLayer(_layer);
        };
    }, [map]);

    useEffect(() => {
        layer.current.setSource(source);
    }, [source]);

    useEffect(() => {
        layer.current.setMinResolution(minResolution);
    }, [minResolution]);

    useEffect(() => {
        layer.current.setMaxResolution(maxResolution);
    }, [maxResolution]);

    return null;
}

export default memo(Tile);
