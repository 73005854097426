import { getArea, getLength } from "ol/sphere";
import { LineString } from "ol/geom";

export const formatArea = (area) => {
    if (area < 0.01) {
        // Area will sometimes return like 0.0000011234134 for a line
        return null;
    }
    if (area < 1000000) {
        return `${area.toLocaleString(undefined, { maximumFractionDigits: 0 })} m\u00B2`;
    }
    if (area < 1000000000) {
        return `${(area / 1000000).toLocaleString(undefined, { maximumFractionDigits: 3 })} km\u00B2`;
    }
    return `${(area / 1000000).toLocaleString(undefined, { maximumFractionDigits: 0 })} km\u00B2`;
};

export const formatLength = (length) => {
    if (length < 1000) {
        return `${Math.round(length)} m`;
    }
    if (length < 100000) {
        return `${(length / 1000).toLocaleString(undefined, { minimumFractionDigits: 3 })} km`;
    }
    return `${(length / 1000).toLocaleString(undefined, { maximumFractionDigits: 0 })} km`;
};

export const measure = (geometry, projection, isLine) => {
    const area = getArea(geometry, { projection });

    const coordinates = geometry.getCoordinates()[0].slice();
    if (isLine) {
        coordinates.pop();
    }
    const lineString = new LineString(coordinates);
    const length = getLength(lineString, { projection });

    const areaText = formatArea(area);
    const lengthText = formatLength(length);

    return { area, length, areaText, lengthText };
};
