const SEARCH_URL = "https://secure.geonames.org/searchJSON?username=sverigekartan&country=SE";

export const performSearch = async (query, maxRows) => {
    const response = await fetch(`${SEARCH_URL}&maxRows=${maxRows}&name_startsWith=${query}`);
    const result = await response.json();
    if (!result || !Array.isArray(result.geonames)) {
        throw new Error("Ett fel uppstod");
    }
    return result.geonames;
};
