export const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
};

export const hexToRgbArray = hex => {
    const rgb = hexToRgb(hex);
    return rgb ? [rgb.r, rgb.g, rgb.b] : null;
};
