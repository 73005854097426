import clsx from "clsx";

import styles from "./IconButton.scss";

export const IconButton = ({ className, icon, ariaLabel, onClick, active, variant = "outlined" }) => {
    return (
        <div className={className}>
            <button
                className={clsx(styles.button, {
                    [styles.active]: active,
                    [styles.outlined]: variant === "outlined",
                    [styles.transparent]: variant === "transparent",
                })}
                ariaLabel={ariaLabel}
                onClick={onClick}
            >
                {icon}
            </button>
        </div>
    );
};
