import { Link } from "./Link";
import { EnvelopeSolid } from "./icons";

import styles from "./Footer.scss";

export const Footer = () => {
    return (
        <div className={styles.container}>
            <p>
                &copy; 2021{" "}
                <a href="https://tajgastudio.com" target="_blank" rel="noopener noreferrer">
                    Tajga Studio
                </a>
                <Link className={styles.mail} url="mailto:hej@tajgastudio.com" icon={<EnvelopeSolid />} />
                Kartmaterial från Lantmäteriet
            </p>
        </div>
    );
};
