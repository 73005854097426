import { useContext, useEffect, useRef, useState } from "preact/hooks";

import { fromLonLat } from "ol/proj";
import { Feature as OLFeature } from "ol-preact";
import { MapContext } from "ol-preact/Map";
import { Vector as OLVectorLayer } from "ol-preact/layer";
import { Vector as OLVectorSource } from "ol-preact/source";
import { getAccuracyStyle, getPositionStyle } from "../utils/olStyles";

export const GeolocationLayer = ({ position }) => {
    const { map } = useContext(MapContext);
    const prevPositionCoords = useRef(null);
    const [positionCoords, setPositionCoords] = useState(null);
    const [positionGeometry, setPositionGeometry] = useState(null);
    const positionStyle = useRef(getPositionStyle());
    const accuracyStyle = useRef(getAccuracyStyle());

    useEffect(() => {
        // Update accuracy radius as it changes & when map resolution changes
        const updateAccuracy = () => {
            if (position) {
                let radius = position.coords.accuracy / map.getView().getResolution();
                if (Number.isNaN(radius) || radius < 10) {
                    radius = 0;
                }
                accuracyStyle.current.getImage().setRadius(radius);
            }
        };
        map?.getView().on("change:resolution", updateAccuracy);
        return () => {
            map?.getView().un("change:resolution", updateAccuracy);
        };
    }, [map, position]);

    useEffect(() => {
        if (prevPositionCoords.current === null) {
            map?.getView().animate({ center: positionCoords });
        }
        prevPositionCoords.current = positionCoords;
        if (positionCoords) {
            setPositionGeometry({
                type: "Point",
                coordinates: positionCoords,
            });
        } else {
            setPositionGeometry(null);
        }
    }, [map, positionCoords]);

    useEffect(() => {
        if (position && map) {
            const lonLat = [position.coords.longitude, position.coords.latitude];
            const coordinates = fromLonLat(lonLat, map.getView().getProjection());
            setPositionCoords(coordinates);
        } else {
            setPositionCoords(null);
        }
    }, [position, map]);

    return (
        <OLVectorLayer zIndex={100}>
            <OLVectorSource>
                <OLFeature id="position" geometry={positionGeometry} style={positionStyle.current} />
                <OLFeature id="accuracy" geometry={positionGeometry} style={accuracyStyle.current} />
            </OLVectorSource>
        </OLVectorLayer>
    );
};
