export const getLocalJSONStorage = (key, initialValue) => {
    const value = window.localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    }
    return initialValue;
};

export const setLocalJSONStorage = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};
