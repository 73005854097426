import { useContext, useEffect } from "preact/hooks";
import { MapContext } from "ol-preact/Map";
import olClickZoomInteraction from "../utils/olClickZoomInteraction";

export const DoubleTapZoomInteraction = ({ disableDoubleClick }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        let interaction;
        if (map) {
            interaction = olClickZoomInteraction(map, disableDoubleClick);
            map.addInteraction(interaction);
        }
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, disableDoubleClick]);

    return null;
};
