import { WMTS as WMTSSource } from "ol/source";
import TileGrid from "ol/tilegrid/WMTS";

const WMTS_URL = "https://api.lantmateriet.se/open/topowebb-ccby/v1/wmts/token/";
const API_KEY = "6c92a1c6-0fab-386f-ad78-40bcc94d544c";

export const extents = {
    "EPSG:3006": [-1200000, 4700000, 2600000, 8500000],
    "EPSG:3857": [-20037508.342789, -20037508.342789, 20037508.342789, 20037508.342789],
};
const matrixIds = {
    "EPSG:3006": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    "EPSG:3857": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
};
const resolutions = {
    "EPSG:3006": [4096.0, 2048.0, 1024.0, 512.0, 256.0, 128.0, 64.0, 32.0, 16.0, 8.0],
    "EPSG:3857": [
        156543.033928041,
        78271.51696402048,
        39135.75848201023,
        19567.87924100512,
        9783.939620502561,
        4891.96981025128,
        2445.98490512564,
        1222.99245256282,
        611.49622628141,
        305.7481131407048,
        152.8740565703525,
        76.43702828517624,
        38.21851414258813,
        19.10925707129406,
        9.554628535647032,
        4.777314267823516,
    ],
};

export const createTopowebbSource = (projection) =>
    new WMTSSource({
        url: `${WMTS_URL}${API_KEY}/`,
        layer: "topowebb",
        format: "image/png",
        projection,
        matrixSet: projection.slice(5),
        version: "1.0.0",
        style: "default",
        crossOrigin: "anonymous",
        tileGrid: new TileGrid({
            tileSize: 256,
            extent: extents[projection],
            resolutions: resolutions[projection],
            matrixIds: matrixIds[projection],
        }),
    });
