import { useEffect, useState } from "preact/hooks";

export const useGeolocation = () => {
    const [position, setPosition] = useState(null);
    const [locating, setLocating] = useState(false);
    const [locationEnabled, setLocationEnabled] = useState(false);

    useEffect(() => {
        let watchId;
        if (locationEnabled) {
            setLocating(true);
            watchId = navigator.geolocation.watchPosition((position) => {
                setPosition(position);
                setLocating(false);
            });
        } else {
            setPosition(null);
        }
        return () => {
            watchId && navigator.geolocation.clearWatch(watchId);
        };
    }, [locationEnabled]);

    return { position, locating, locationEnabled, setLocationEnabled };
};
