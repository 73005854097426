import { memo } from "preact/compat";
import { useContext, useEffect, useState } from "preact/hooks";
import { MapContext } from "../Map";

import OLDraw from "ol/interaction/Draw";

function Draw({ type, onDrawStart, onDrawEnd, condition, finishCondition, stopClick, style, minPoints }) {
    const [interaction, setInteraction] = useState(null);
    const { map } = useContext(MapContext);

    useEffect(() => {
        setInteraction(new OLDraw({ type, condition, finishCondition, stopClick, style, minPoints }));
    }, [type, condition, finishCondition, stopClick, style, minPoints]);

    useEffect(() => {
        interaction && map?.addInteraction(interaction);
        return () => {
            interaction && map?.removeInteraction(interaction);
        };
    }, [map, interaction]);

    useEffect(() => {
        onDrawStart && interaction?.on("drawstart", onDrawStart);
        return () => {
            onDrawStart && interaction?.un("drawstart", onDrawStart);
        };
    }, [interaction, onDrawStart]);

    useEffect(() => {
        onDrawEnd && interaction?.on("drawend", onDrawEnd);
        return () => {
            onDrawEnd && interaction?.un("drawend", onDrawEnd);
        };
    }, [interaction, onDrawEnd]);

    return null;
}

export default memo(Draw);
