import { useMemo } from "preact/hooks";
import { getLocalJSONStorage } from "../utils/localJSONStorage";
import { getDefaultResolution } from "../utils/getDefaultResolution";

export const useInitialView = (projection) =>
    useMemo(() => {
        const center = getLocalJSONStorage("sverigekartan_center", [570000, 6900000]);
        const resolution = getLocalJSONStorage("sverigekartan_resolution", getDefaultResolution(projection));
        return { center, resolution, projection };
    }, [projection]);
