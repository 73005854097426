import { useCallback, useContext, useEffect, useState } from "preact/hooks";
import { Link } from "./Link";
import { toLonLat } from "ol/proj";
import { createStringXY } from "ol/coordinate";
import { Overlay as OLOverlay } from "ol-preact";
import { MapContext } from "ol-preact/Map";
import { ShareAltSquareSolid, TimesCircleSolid } from "./icons";

import styles from "./Popup.scss";

const createCoordinateString = createStringXY(4);
const autoPanOptions = {
    animation: {
        duration: 250,
    },
};

export const Popup = ({ coordinates, title, onClose }) => {
    const { map } = useContext(MapContext);

    const [swerefCoordinates, setSwerefCoordinates] = useState(null);
    const [wgs84Coordinates, setWgs84fCoordinates] = useState(null);
    const [sverigekartanUrl, setSverigekartanUrl] = useState(null);
    const [googleMapsUrl, setGoogleMapsUrl] = useState(null);

    const share = useCallback(() => {
        navigator.share({ title: "Länk till Sverigekartan", url: sverigekartanUrl });
    }, [sverigekartanUrl]);
    useEffect(() => {
        if (coordinates) {
            const [x, y] = coordinates;
            const lonLat = toLonLat(coordinates, map?.getView().getProjection());
            setSwerefCoordinates(createCoordinateString(coordinates));
            setWgs84fCoordinates(createCoordinateString(lonLat));
            setSverigekartanUrl(`https://sverigekartan.app/?x=${x}&y=${y}`);
            setGoogleMapsUrl(`https://www.google.com/maps/search/?api=1&query=${lonLat[1]},${lonLat[0]}`);
        } else {
            setSwerefCoordinates(null);
            setWgs84fCoordinates(null);
            setSverigekartanUrl(null);
            setGoogleMapsUrl(null);
        }
    }, [coordinates, map]);

    if (!coordinates) {
        return null;
    }

    return (
        <OLOverlay position={coordinates} autoPan={autoPanOptions} positioning="bottom-center">
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    {title && <h3 className={styles.title}>{title}</h3>}
                    <h4 className={styles.header}>Coordinates (SWEREF)</h4>
                    <p>{swerefCoordinates}</p>
                    <h4 className={styles.header}>Coordinates (WGS84)</h4>
                    <p>{wgs84Coordinates}</p>
                    <div className={styles.links}>
                        {typeof navigator.share === "function" ? (
                            <Link onClick={share} icon={<ShareAltSquareSolid />}>
                                Dela
                            </Link>
                        ) : (
                            <Link url={sverigekartanUrl}>Sverigekartan</Link>
                        )}
                        <Link url={googleMapsUrl}>Google Maps</Link>
                    </div>
                </div>
                <button className={styles.closeButton} onClick={onClose}>
                    <TimesCircleSolid />
                </button>
            </div>
        </OLOverlay>
    );
};
